import { TextInput } from '../../../../storyBook';
import { useTranslation } from 'react-i18next';
import {
  firstNameValidation,
  lastNameValidation,
  emailValidation,
  phoneNumberValidation,
  passwordValidation,
  confirmPasswordValidation,
} from '../../../Utils/validation';

import { StageOneProps, StageTwoProps, StageThreeProps } from './types';

export const StageOne: React.FC<StageOneProps> = ({
  form,
  handleInputChange,
  setShowErrorMessage,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        autoFocus
        placeholder={t('First Name')}
        value={form.firstName}
        onChange={handleInputChange('firstName')}
        validation={(value) => firstNameValidation(value, t)}
        onValidation={setShowErrorMessage}
      />
      <TextInput
        placeholder={t('Last Name')}
        value={form.lastName}
        onChange={handleInputChange('lastName')}
        validation={(value) => lastNameValidation(value, t)}
        onValidation={setShowErrorMessage}
      />
    </>
  );
};

export const StageTwo: React.FC<StageTwoProps> = ({
  form,
  emailData,
  phoneData,
  handleInputChange,
  setShowErrorMessage,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        autoFocus
        placeholder={t('Email')}
        value={form.email}
        onChange={handleInputChange('email')}
        validation={(value) => emailValidation(value, emailData?.exists, t)}
        onValidation={setShowErrorMessage}
      />
      <TextInput
        placeholder={t('Mobile Number')}
        value={form.phoneNumber}
        onChange={handleInputChange('phoneNumber')}
        validation={(value) =>
          phoneNumberValidation(value, phoneData?.exists, t)
        }
        onValidation={setShowErrorMessage}
      />
    </>
  );
};

export const StageThree: React.FC<StageThreeProps> = ({
  form,
  handleInputChange,
  setShowErrorMessage,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        autoFocus
        placeholder={t('Password')}
        type="password"
        value={form.password}
        onChange={handleInputChange('password')}
        validation={(value) => passwordValidation(value, t)}
        onValidation={setShowErrorMessage}
      />
      <TextInput
        placeholder={t('Confirm Password')}
        type="password"
        value={form.confirmPassword}
        onChange={handleInputChange('confirmPassword')}
        validation={(value) =>
          confirmPasswordValidation(form.password, value, t)
        }
        onValidation={setShowErrorMessage}
      />
    </>
  );
};
