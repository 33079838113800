import styled, { keyframes } from 'styled-components';
import { rem } from 'polished';
import theme from '../../theme';
import { Box } from '../Grid';

interface LabelProps {
  isFocused: boolean;
  background?: string;
}

interface backgroundProps {
  background?: string;
}

export const Container = styled(Box)<backgroundProps>`
  background-color: ${({ background, theme }) =>
    background || theme.colors.modalBackground};

  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: ${rem(16)};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.borderColor};
  border-radius: ${rem(4)};
  padding: ${rem(12)} ${rem(12)};

  &:focus-within {
    border: ${rem(1)} solid ${({ theme }) => theme.colors.supportingBlueLight};
  }
`;

export const Label = styled.label<LabelProps>`
  background-color: ${({ background, theme }) =>
    background || theme.colors.modalBackground};
  position: absolute;
  left: ${rem(12)};
  top: ${({ isFocused }) => (isFocused ? rem(-4) : rem(12))};
  font-size: ${({ isFocused }) => (isFocused ? rem(12) : rem(16))};
  color: ${({ isFocused, theme }) =>
    isFocused ? theme.colors.supportingBlue : theme.colors.textColors};
  padding: 0 ${rem(4)};
  transition: top 0.2s, font-size 0.2s, color 0.2s;
`;

export const Input = styled.input<backgroundProps>`
  color: theme.colors.secondary;
  background-color: ${({ background, theme }) =>
    background || theme.colors.modalBackground};
  color: ${({ theme }) => theme.colors.textColors};
  bold: 600;
  border: none;
  width: 100%;
  font-size: ${rem(16)};
  height: ${rem(24)};
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: transparent;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: theme.colors.cardBackground !important;
  }

  /* Hide arrows for number input in Chrome, Safari, and Edge */
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Hide arrows for number input in Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ClearIcon = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const SuccessMessage = styled.p`
  color: ${theme.colors.veryDark};
  font-weight: 600;
  font-size: ${rem(12)};
  margin-top: ${rem(4)};
  width: 100%;
  height: ${rem(16)};
  padding: ${rem(4)} ${rem(8)};
  margin-bottom: 20px;
  animation: ${slideUp} 0.5s ease-out forwards;
`;

export const TogglePasswordIcon = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  type: button;
`;

export const Asterisk = styled.div`
  color: ${({ theme }): string => theme.colors.primaryRed};
  font-size: ${rem(18)};
  font-weight: 400;
  margin-left: 1px;
`;
