import { RadioButtonProps } from './types';
import {
  HiddenRadioInput,
  OptionText,
  RadioLabel,
  RadioButtonWrapper,
  RadioThumb,
} from './style';

const RadioButton = ({
  value,
  onChange,
  options,
  ariaLabel,
  name = undefined,
  title = 'Radio Button',
  isDisabled = false,
  isCompact = false,
}: RadioButtonProps) => {
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <RadioButtonWrapper isCompact={isCompact}>
      {options.map((option, index) => (
        <RadioLabel key={index} isCompact={isCompact} isDisabled={isDisabled}>
          <HiddenRadioInput
            aria-label={ariaLabel}
            checked={value === option.value}
            disabled={isDisabled}
            name={name}
            onChange={handleRadioChange}
            title={title}
            type="radio"
            value={option.value}
          />
          <RadioThumb />
          <OptionText>{option.label}</OptionText>
        </RadioLabel>
      ))}
    </RadioButtonWrapper>
  );
};

export default RadioButton;
