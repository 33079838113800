import { useRef, useEffect } from 'react';
import { MdModeEditOutline, MdRemoveDone } from 'react-icons/md';
import { FaTrashCan } from 'react-icons/fa6';
import { FiCheckCircle } from 'react-icons/fi';
import { Text } from '../../../storyBook';
import { Menu, MenuItem } from './style';
import theme from '../../../theme';
import { notify } from '../../../storyBook/TostNotification';
import useTimeline from '../../hooks/useTimeline';
import { FaLink } from 'react-icons/fa';
import { FaStaylinked } from 'react-icons/fa6';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface MenuProps {
  id: string;
  timelineId?: string;
  snapNote?: boolean;
  linkSnapNote?: boolean;
  addToTimeline?: boolean;
  snapNoteDownload?: boolean;
  removeSnapNote?: boolean;
  addSnapnoteToTimeline?: (snapnoteId: string, timelineId: string) => void;
  onLinkSnapNote?: (id: string) => void;
  password?: boolean;
  showEditOption: boolean;
  showStatusOption?: boolean;
  onEdit?: (id: string) => void;
  onDelete: (id: string) => void;
  setOpenMenuId: (value: string | null) => void;
  status?: 'Done' | 'Pending';
}

const SubMenu: React.FC<MenuProps> = ({
  id,
  timelineId,
  snapNote = false,
  password = false,
  showEditOption,
  showStatusOption,
  onLinkSnapNote,
  removeSnapNote,
  linkSnapNote,
  addToTimeline,
  setOpenMenuId,
  addSnapnoteToTimeline,
  onEdit,
  onDelete,
  status,
}) => {
  const { editTimeline } = useTimeline();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const history = useHistory();
  const { t } = useTranslation();

  // Convert relative path to absolute URL

  const handleStatusUpdate = async (id: string, status: 'Done' | 'Pending') => {
    try {
      await editTimeline(id, { status });
      notify({
        message: `${t('Timeline marked as')} ${status.toLowerCase()}`,
        type: 'success',
      });
      setOpenMenuId(null);
    } catch (err) {
      console.error(`Error updating Timeline status to ${status}:`, err);
      notify({
        message: `${t('Error updating Timeline status to')} ${status}`,
        type: 'error',
      });
    }
  };

  // Close menu when clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenMenuId(null);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);
  return (
    <>
      <Menu ref={menuRef}>
        {showStatusOption && (
          <MenuItem
            onClick={() =>
              handleStatusUpdate(id, status === 'Done' ? 'Pending' : 'Done')
            }
          >
            {status === 'Done' ? (
              <>
                <MdRemoveDone size={14} color={theme.colors.pending} />
                <Text as="small" ml={1} color={theme.colors.pending}>
                  {t('Mark as Pending')}
                </Text>
              </>
            ) : (
              <>
                <FiCheckCircle size={14} color={theme.colors.success} />
                <Text as="small" ml={1} color={theme.colors.success}>
                  {t('Mark as Done')}
                </Text>
              </>
            )}
          </MenuItem>
        )}

        {showEditOption && (
          <MenuItem onClick={() => onEdit && onEdit(id)}>
            <MdModeEditOutline size="15" />
            <span>{t('Edit')}</span>
          </MenuItem>
        )}

        {linkSnapNote && (
          <MenuItem onClick={() => onLinkSnapNote && onLinkSnapNote(id)}>
            <FaLink size="15" color={theme.colors.supportingBlue} />
            <Text as="small" ml={1}>
              {t('Link to Snapnote')}
            </Text>
          </MenuItem>
        )}

        {timelineId && addToTimeline && (
          <MenuItem
            onClick={() => {
              addSnapnoteToTimeline &&
                addSnapnoteToTimeline(timelineId || '', id);
              setOpenMenuId(null);
              history.push('/timeline');
            }}
          >
            <FaStaylinked size="15" color={theme.colors.supportingBlue} />
            <Text as="small" ml={1}>
              {t('Add to Timeline')}
            </Text>
          </MenuItem>
        )}

        <MenuItem onClick={() => onDelete(id)}>
          <FaTrashCan color={theme.colors.primaryRed} size="15" />
          <span>
            {removeSnapNote ? t('Remove') : t('Delete')}{' '}
            {snapNote
              ? t('snapnote')
              : password
              ? t('password')
              : t('timeline')}
          </span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SubMenu;
