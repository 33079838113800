import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { StyleCard, SiteLogo } from '../PasswordList/style';
import {
  Back,
  Flex,
  Box,
  TextInput,
  Text,
  RotatableIcon,
} from '../../../../storyBook';
import usePassword from '../../../hooks/usePassword';
import theme from '../../../../theme';
import { Hr } from '../../../_common/style';
import Loader from '../../../_common/Loader';
import { RotableContainer } from './style';
import SubMenu from '../../../Timeline/SubMenu';
import AddPasswordModal from '../AddPasswordModal';
import { decrypt, deriveHexKey } from '../../../Utils/helper';
import useAuth from '../../../hooks/useAuth';

interface Params {
  passwordId: string;
}

const PasswordCard = () => {
  const { passwords, deletePassword, editPassword, fetchAllPasswords } =
    usePassword();
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const history = useHistory();

  const { passwordId } = useParams<Params>();
  const passwordItem = passwords.find((p) => p._id === passwordId);

  // Retrieve the user's Master Password from localStorage
  const { masterPasswords } = useAuth();
  // Derive a hex key
  const derivedHexKey = deriveHexKey(masterPasswords);

  // Decrypt the stored password client-side
  let decryptedPassword = '';
  if (passwordItem && passwordItem.password && masterPasswords) {
    decryptedPassword = decrypt(passwordItem.password, derivedHexKey);
  }

  if (!passwordItem) {
    return <Loader isLoading={true} />;
  }

  // Toggle SubMenu
  const handleMenuToggle = (id: string | null) => {
    setOpenMenuId(id);
  };

  // Handle form submission from the modal
  const handleEditSubmit = async (updatedData: {
    website: string;
    username: string;
    password: string;
  }) => {
    await editPassword(passwordItem._id ?? '', updatedData);
    await fetchAllPasswords();
    setIsModalOpen(false);
  };

  // Open the edit modal
  const handleEdit = () => {
    setIsModalOpen(true);
    setModalProps({
      isEditMode: true,
      initialData: {
        site: passwordItem.website,
        userName: passwordItem.username,
        password: decryptedPassword, // plain text for user
      },
      onEdit: handleEditSubmit,
    });
  };

  // Delete a password
  const handleDelete = async () => {
    if (passwordItem._id) {
      await deletePassword(passwordItem._id);
    }
    history.push('/password');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <StyleCard>
      <Flex flexDirection="column">
        <RotableContainer>
          <RotatableIcon
            _id={passwordItem._id || ''}
            onToggle={handleMenuToggle}
            isOpen={openMenuId === passwordItem._id}
          />
          {openMenuId === passwordItem._id && (
            <SubMenu
              id={passwordItem._id}
              password
              showEditOption
              onEdit={handleEdit}
              onDelete={handleDelete}
              setOpenMenuId={setOpenMenuId}
            />
          )}
        </RotableContainer>
        <Flex mb={3} alignItems="center">
          <Box pr={3}>
            <Back arrow onClick={handleBack} />
          </Box>
          <SiteLogo src={passwordItem.logoUrl} alt="logo" />
          <Text as="h3" color={theme.colors.offBlack}>
            {passwordItem.website}
          </Text>
        </Flex>
        <Hr mb={4} />
        <Box mb={4}>
          {/* Show username directly */}
          <TextInput
            placeholder="Username"
            value={passwordItem.username}
            onChange={() => {}}
            isCopy
          />
          {/* Show decrypted password for user */}
          <TextInput
            type="password"
            placeholder="Password"
            value={decryptedPassword}
            onChange={() => {}}
            isCopy
          />
        </Box>
        {isModalOpen && (
          <AddPasswordModal setAddModal={closeModal} {...modalProps} />
        )}
      </Flex>
    </StyleCard>
  );
};

export default PasswordCard;
