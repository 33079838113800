import {
  Container,
  Title,
  LastUpdated,
  Section,
  Heading,
  List,
  ListItem,
  TextPara,
  InnerSection,
} from './style';
import { Card, Box, Flex, useIsMedia, Back } from '../storyBook';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy: React.FC = () => {
  const { isMobile } = useIsMedia();
  const { t } = useTranslation();

  return (
    <>
      <Box mb={3} ml={3}>
        <Back arrow onClick={() => history.back()} />
      </Box>

      <Container>
        <Flex>
          <img
            src="/livediary-logo.png"
            alt="Live Diary Logo"
            width="50px"
            height="50px"
          />
          <Title as="h2">{t('Privacy Policy')}</Title>
        </Flex>

        <LastUpdated mt={isMobile ? -3 : 0}>
          {t('Last updated')}: 29 May 2024
        </LastUpdated>

        <Section>
          <Heading>{t('Welcome')}</Heading>
          <TextPara>
            {t(
              'Thank you for choosing to be part of our community at Live Diary. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to personal information.'
            )}
          </TextPara>
        </Section>

        <Section>
          <Heading>{t('1. What Information Do We Collect?')}</Heading>
          <Heading>{t('1.1 Personal Information You Disclose to Us')}</Heading>
          <TextPara>
            {t(
              'We collect personal information that you provide to us such as name, address, contact information, passwords and security data, and payment information. This information is primarily needed to maintain the security and operation of our App, and for our internal analytics and reporting purposes.'
            )}
          </TextPara>
          <Heading>{t('1.2 Information Automatically Collected')}</Heading>
          <TextPara>
            {t(
              'We automatically collect certain information when you visit, use or navigate the App. This information includes device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, and more.'
            )}
          </TextPara>
        </Section>

        <Section>
          <Heading>{t('2. How Do We Use Your Information?')}</Heading>
          <Box ml={!isMobile ? 5 : 0}>
            <List>
              <ListItem>
                {t('To facilitate account creation and login process.')}
              </ListItem>
              <ListItem>
                {t('To send administrative information to you.')}
              </ListItem>
              <ListItem>{t('To fulfill and manage your orders.')}</ListItem>
              <ListItem>
                {t('To respond to user inquiries and offer support to users.')}
              </ListItem>
              <ListItem>
                {t(
                  'To enhance the performance and functionality of our services but are non-essential to their use.'
                )}
              </ListItem>
            </List>
          </Box>
        </Section>

        <Section>
          <Heading>
            {t('3. Will Your Information Be Shared with Anyone?')}
          </Heading>
          <TextPara>
            {t(
              'We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations. We may also share your data with our affiliates, in which case we will require those affiliates to honor this privacy policy.'
            )}
          </TextPara>
        </Section>

        <Section>
          <Heading>{t('4. How Do We Handle Your Social Logins?')}</Heading>
          <TextPara>
            {t(
              'Our App offers you the ability to register and login using your third-party social media account details. The profile information we receive may vary depending on the social media provider concerned, but will generally include your name, email address, friends list, profile picture, and other information you choose to make public.'
            )}
          </TextPara>
        </Section>

        <Section>
          <Heading>{t('5. Data Retention')}</Heading>
          <TextPara>
            {t(
              'We will retain your personal information only for as long as is necessary for the purposes set out in this privacy policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.'
            )}
          </TextPara>
        </Section>

        <Section>
          <Heading>{t('6. Your Data Protection Rights')}</Heading>
          <Box ml={!isMobile ? 5 : 0}>
            <List>
              <ListItem>
                {t(
                  'You have the right to request access to the personal information we hold about you.'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'You have the right to request that we correct any inaccuracies in that information.'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'You have the right to request the deletion of your data under certain circumstances.'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'You have the right to object to our processing of your data.'
                )}
              </ListItem>
            </List>
          </Box>
        </Section>
        <Card py={3}>
          <Section>
            <InnerSection>
              <Heading>{t('Contact Us')}</Heading>
              <TextPara>
                {t(
                  'If you have questions or comments about this policy, you may email us at'
                )}{' '}
                <strong>
                  <a href="mailto: live.diary23@gmail.com">
                    live.diary23@gmail.com
                  </a>
                </strong>
              </TextPara>
            </InnerSection>
          </Section>
        </Card>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
