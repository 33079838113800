import styled from 'styled-components';
import { rem } from 'polished';

export const Input = styled.div<{ open: boolean; isFocused: boolean }>`
  min-height: ${rem(48)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }): string => theme.spacing.xs};
  color: ${({ theme }): string => theme.colors.medium};
  border-radius: ${({ open }: { open: boolean }): string =>
    open ? `${rem(4)} ${rem(4)} 0 0` : rem(4)};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 600;
  cursor: pointer;
  background-color: ${({ theme }): string => theme.colors.modalBackground};

  border: ${({ theme, isFocused }) =>
    isFocused
      ? `2px solid ${theme.colors.supportingBlueLight}`
      : `1px solid ${theme.colors.borderColor}`};
`;

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const List = styled.ul<{ isFocused: boolean }>`
  appearance: none;
  position: absolute;
  top: 98%;
  list-style-type: none;
  border-radius: 0 0 ${rem(4)} ${rem(4)};
  border-top: none;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;
  border: ${({ theme, isFocused }) =>
    isFocused
      ? `2px solid ${theme.colors.supportingBlueLight}`
      : `1px solid ${theme.colors.borderColor}`};

  max-height: ${rem(250)};
  overflow-y: auto;

  li:last-child {
    border-radius: 0 0 ${rem(4)} ${rem(4)};
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  font-weight: 500;
  padding: ${({ theme }): string => theme.spacing.xs};
  background-color: ${({ theme }): string => theme.colors.cardBackground};
  color: ${({ theme }): string => theme.colors.textColors};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }): string => theme.colors.ultraLight};
  }
`;

export const Placeholder = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.textColors : theme.colors.medium};
  margin-right: ${({ theme }): string => theme.spacing.xs};
  font-size: 14px;
`;

export const ArrowWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }): string => theme.colors.textColors};
`;

export const Asterisk = styled.div`
  color: ${({ theme }): string => theme.colors.primaryRed};
  font-size: ${rem(18)};
  font-weight: 400;
  margin-left: 1px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem(14)};
  font-weight: 600;
  text-align: left;
  color: ${({ theme }): string => theme.colors.textColors};
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  left: 0;
  margin-bottom: ${rem(5)};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
