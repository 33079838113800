import React, { useState, useRef, useEffect } from 'react';
import useSnapNote from '../../hooks/useSnapNote';
import { IoCameraSharp } from 'react-icons/io5';
import { MdOutlinePermMedia } from 'react-icons/md';
// import { SnapNoteData } from '../../Context/SnapNoteContext/types';
import {
  Back,
  Flex,
  Box,
  Text,
  Button,
  Modal,
  TextArea,
  useIsMedia,
} from '../../../storyBook';
import { Hr, Img } from '../../_common/style';
import { CaptureButton, StyleInput, StyleCanvas, StyleLinkText } from './style';
import { Badge } from '../../Dashboard/style';
import { validateNoteForm } from '../../Utils/validation';
import { ErrorMessage } from '../../_common/style';
import theme from '../../../theme';
import { useHistory, useParams } from 'react-router-dom';
import { resizeImage } from '../../Utils/resizeImage';
import { ImSpinner8 } from 'react-icons/im';
import { useTranslation } from 'react-i18next';

const AddSnapNoteComponent = () => {
  const { snapNotes, addSnapNote, editSnapNote, loading } = useSnapNote();
  const { noteId } = useParams<{ noteId?: string }>();
  const isEditMode = noteId != null;
  const [content, setContent] = useState<string>('');
  const [images, setImages] = useState<File[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isCapture, setisCapture] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [lastAction, setLastAction] = useState<'media' | 'camera' | null>(null);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [capturedImage, setCapturedImage] = useState<File | null>(null);
  const [label, setLabel] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { isMobile, isTablet } = useIsMedia();
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const pathHistory = useHistory();
  const { t } = useTranslation();

  const validationErrors = validateNoteForm(label || '');
  const isFormValid = Object.keys(validationErrors).length === 0;

  // Detect if the device is mobile or tablet
  const isMobileDevice =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      navigator.userAgent
    );

  useEffect(() => {
    if (isEditMode && noteId) {
      const snapNoteEdit = snapNotes.find((note) => note._id === noteId);

      if (snapNoteEdit) {
        setContent(snapNoteEdit.content ?? '');
        setLabel(snapNoteEdit.label || '');
        if (snapNoteEdit.images) {
          const imageFiles = snapNoteEdit.images.map(
            (imageName) => new File([], imageName)
          );
          setImages(imageFiles);
        }
      }
      setIsDataChanged(false);
    }
  }, [noteId, isEditMode, snapNotes]);

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
    setIsDataChanged(true);
  };
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const finalizeImageUpload = (finalFile: File) => {
        const previewUrl = URL.createObjectURL(finalFile);
        setImagePreview(previewUrl);
        setCapturedImage(finalFile);
        setIsDataChanged(true);

        if (isMobileDevice) {
          // Since mobile devices handle their own preview, add the image immediately
          setImages((prevImages) => [...prevImages, finalFile]);
          setCapturedImage(null);
          setImagePreview(null);
        } else {
          // Show preview modal on desktop
          setShowPreviewModal(true);
        }

        setShowModal(false);
      };

      if (file.size > 5 * 1024 * 1024) {
        // Resize image if it's larger than 5 MB
        resizeImage(file, 800, 600, 0.7, (resizedBlob) => {
          if (resizedBlob) {
            const resizedFile = new File([resizedBlob], file.name, {
              type: file.type,
            });
            finalizeImageUpload(resizedFile);
          } else {
            console.error('Failed to resize image');
            // Optionally handle the error, e.g., show an error message
          }
        });
      } else {
        finalizeImageUpload(file);
      }
    }
  };

  const startCamera = async () => {
    setisCapture(true);

    if (isMobileDevice) {
      // For mobile devices, trigger file input for native camera app
      if (fileInputRef.current) {
        fileInputRef.current.accept = 'image/*';
        fileInputRef.current.capture = 'environment';
        fileInputRef.current.click();
      }
    } else {
      // For desktop devices, start the camera stream
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
          setShowModal(false); // Close modal
        } catch (error) {
          console.error('Error accessing the camera:', error);
        }
      }
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const mediaStream = videoRef.current.srcObject as MediaStream;
      const tracks = mediaStream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        canvasRef.current.toBlob((blob) => {
          if (blob) {
            const newImageFile = new File([blob], 'captured_image.jpeg', {
              type: 'image/jpeg',
            });
            const previewUrl = URL.createObjectURL(blob);
            setImagePreview(previewUrl);
            setShowPreviewModal(true);
            setLastAction('camera');
            setCapturedImage(newImageFile);
            stopCamera();
            setIsDataChanged(true);
          }
        }, 'image/jpeg');
      }
    }
  };
  const handleRetry = () => {
    setImagePreview(null);
    setShowPreviewModal(false);
    if (lastAction === 'media' && fileInputRef.current) {
      fileInputRef.current.click();
    } else if (lastAction === 'camera') {
      startCamera();
    }
  };

  const handleOk = () => {
    if (capturedImage) {
      setImages((prevImages) => [...prevImages, capturedImage]);
      setCapturedImage(null); // Reset the temporary captured image
    }
    setShowPreviewModal(false);
    setisCapture(false);
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLabel(e.target.value);
    setIsDataChanged(true);
  };

  const handleAddSnapNote = async () => {
    setSubmitAttempted(true);
    if (!isFormValid) return;

    const formData = new FormData();
    if (content) {
      formData.append('content', content);
    }
    if (label) {
      formData.append('label', label);
    }

    // Filter out any empty or undefined files
    const nonEmptyImages = images.filter((file) => file.size > 0);
    nonEmptyImages.forEach((image) => formData.append('images', image));

    try {
      if (isEditMode && noteId) {
        await editSnapNote(noteId, formData);
        // Clear the file input and image preview state
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setImagePreview(null);
        setImages([]);
        pathHistory.goBack();
      } else {
        await addSnapNote(formData);
        // Reset fields after submission
        setContent('');
        setLabel('');
        setImages([]);
        setShowModal(false); // Close modal
      }
      setSubmitAttempted(false);
    } catch (error) {
      console.error('Error adding/editing snap note:', error);
    }
  };

  const handleLabelBlur = () => {
    setSubmitAttempted(true);
  };
  const handleBack = () => {
    history.back();
  };
  return (
    <Box width={isMobile || isTablet ? '100%' : '450px'} mb={4} p={[2, 2, 3]}>
      <Box mb={3}>
        <Back arrow onClick={handleBack} />
      </Box>
      <Flex mb={4} justifyContent="space-between">
        <Text as="h2" bold color={theme.colors.mediumDark}>
          {isEditMode ? t('Edit a Snapnote') : t('Write a Snapnote')}
        </Text>
        <Text bold>
          {t('Images')} <Badge as="span">{images.length}</Badge>
        </Text>
      </Flex>
      <Text mb={2}>
        {isEditMode
          ? t('Edit your SnapNote by updating the content, label, or images.')
          : t('Write your SnapNote by adding content, label, or images.')}
      </Text>
      <Box mb={2}>
        <TextArea
          label={t('Label')}
          rows={1}
          name="Label"
          value={label}
          onChange={handleLabelChange}
          onBlur={handleLabelBlur}
          placeholder={t('Enter a label for this SnapNote')}
          required
        />
        {submitAttempted && validationErrors.label && (
          <ErrorMessage>{validationErrors.label}</ErrorMessage>
        )}
      </Box>

      <TextArea
        label={t('SnapNote')}
        rows={8}
        name="SnapNote"
        value={content}
        onChange={handleContentChange}
        placeholder={t('Write your Note here...')}
      />
      {submitAttempted && validationErrors.images && (
        <ErrorMessage>{validationErrors.images}</ErrorMessage>
      )}
      {showModal && (
        <Modal onClose={() => setShowModal(false)} isOpen={showModal}>
          <Box p={4}>
            <Text bold mb={3}>
              Choose a document to upload or take a photo.
            </Text>
            <Box mb={3}>
              <Hr />
            </Box>

            <Flex justifyContent="space-between">
              <Flex width="60px" flexDirection="column" alignItems="center">
                <Button
                  styleType="secondary"
                  size="small"
                  onClick={() => {
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  <MdOutlinePermMedia size={30} />
                </Button>
                <Text as="small">Media</Text>
              </Flex>
              <Flex width="60px" flexDirection="column" alignItems="center">
                <Button onClick={startCamera} styleType="danger" size="small">
                  <IoCameraSharp size={30} />
                </Button>
                <Text as="small">{t('Camera')}</Text>
              </Flex>
            </Flex>
          </Box>
        </Modal>
      )}
      {showPreviewModal && !isMobileDevice && (
        <Modal
          onClose={() => setShowPreviewModal(false)}
          isOpen={showPreviewModal}
        >
          <Box p={4} m={2}>
            <Img src={imagePreview || ''} alt="Preview" />
            <Flex justifyContent="space-between">
              <StyleLinkText onClick={handleRetry}>Retry</StyleLinkText>
              <StyleLinkText onClick={handleOk}>Use Photo</StyleLinkText>
            </Flex>
          </Box>
        </Modal>
      )}

      <StyleInput
        type="file"
        ref={fileInputRef}
        onChange={handleImageChange}
        multiple
      />
      <Box mb={3} mt={4}>
        <Button bold size="medium" onClick={() => setShowModal(true)}>
          {isEditMode && images.length > 0
            ? t('Upload More Photos')
            : t('Take Photo')}
        </Button>
      </Box>

      {!isMobileDevice && isCapture && (
        <Box>
          <Modal onClose={() => setisCapture(false)} isOpen={isCapture}>
            <video ref={videoRef} autoPlay width="800" height="800" />
            <Box mb={2} width="360px">
              <CaptureButton onClick={captureImage} />
            </Box>
          </Modal>

          <StyleCanvas ref={canvasRef} width="600" height="300"></StyleCanvas>
        </Box>
      )}
      <Button
        bold
        size="medium"
        onClick={handleAddSnapNote}
        disabled={!isFormValid || (isEditMode && !isDataChanged) || loading}
      >
        {loading ? (
          <ImSpinner8 size={20} style={{ verticalAlign: 'middle' }} />
        ) : isEditMode ? (
          t('Update Snapnote')
        ) : (
          t('Add Snapnote')
        )}
      </Button>
    </Box>
  );
};

export default AddSnapNoteComponent;
