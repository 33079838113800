import React from 'react';
import { RxDashboard } from 'react-icons/rx';
import { PiListChecksLight } from 'react-icons/pi';
import { MdOutlinePhoto } from 'react-icons/md';
import useIsMedia from '../../storyBook/hooks/useIsMedia';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';
import { MdPassword } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

interface MenuItem {
  title: string;
  icon: React.ReactNode;
  route: string;
}

const Navigation: React.FC = () => {
  const { isMobile, isTablet } = useIsMedia();
  const { t } = useTranslation();

  const combined = isMobile || isTablet;
  // Calculate the count of unread notifications

  // Define the menu items
  const menuItems: MenuItem[] = [
    {
      title: t('Dashboard'),
      icon: (
        <RxDashboard size={isMobile ? '25px' : isTablet ? '25px' : '20px'} />
      ),
      route: '/dashboard',
    },
    {
      title: t('Timelines'),
      icon: (
        <PiListChecksLight
          size={isMobile ? '25px' : isTablet ? '25px' : '20px'}
        />
      ),
      route: '/timeline',
    },
    {
      title: t('Snapnotes'),
      icon: (
        <MdOutlinePhoto size={isMobile ? '25px' : isTablet ? '25px' : '20px'} />
      ),
      route: '/snapnotes',
    },
    {
      title: t('Password'),
      icon: (
        <MdPassword size={isMobile ? '25px' : isTablet ? '25px' : '20px'} />
      ),
      route: '/password',
    },
  ];

  return combined ? (
    <MobileNavigation menu={menuItems} />
  ) : (
    <DesktopNavigation menu={menuItems} />
  );
};

export default Navigation;
