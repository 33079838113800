import { ColorsMap } from './types'; // Importing types from the types.ts file
// lightColors.ts

export const lightColors: ColorsMap = {
  bodyBackground: '#f0f2f5',
  cardBackground: '#FFFFFF',
  primary: '#2AFD80',
  linerBackground: '#d2e5f1',
  statusBackground: '#7f8c8d',
  statusBackgroundCircel: '#95a5a6',
  signBackground: '#f7f7f7',
  listColor: '#7B1FA2',
  listItemColor: '#eaeaea',
  arrowColor: '#333333',
  progressTailColor: '#d6d6d6',
  primaryLight: '#E5FFF1',
  privacyColor: '#2c3e50',
  secondary: '#000000',
  secondardButtonColor: '#E5E5E5',
  white: '#FFFFFF',
  offBlack: '#201F1F',
  veryDark: '#272727',
  mediumDark: '#5D5D5D',
  medium: '#A0A0A0',
  light: '#E8E8E8',
  ultraLight: '#f5f6f7',
  borderColor: '#dcdcdc',
  primaryGreen: '#00FF7A',
  supportingBlue: '#3c66a3',
  supportingBlueLight: '#1877f2',
  supportingBlue50: '#6995d7',
  primaryRed: '#FF0000',
  success: '#32CD32',
  pending: '#FFA500',
  textColors: '#141313',
  modalBackground: '#E8E8E8',
};

// darkColors.ts

export const darkColors: ColorsMap = {
  bodyBackground: '#3c3b3b', // Deep black-gray for the overall background
  cardBackground: '#1F1F1F', // Slightly lighter black for cards
  primary: '#2AFD80', // Bright green for primary actions
  linerBackground: '#292727', // Dark gray for subtle gradients or dividers
  statusBackground: '#424242', // Medium gray for status elements
  statusBackgroundCircel: '#616161', // Slightly lighter gray for circular elements
  signBackground: '#2c2c2c', // Dark gray for forms and dialogs
  listColor: '#BB86FC', // Soft purple for highlights or lists
  listItemColor: '#333333', // Neutral gray for list items
  arrowColor: '#E0E0E0', // Light gray for icons and arrows
  progressTailColor: '#616161', // Subtle gray for progress indicators
  primaryLight: '#373937', // Bright neon green for accents
  privacyColor: '#85add1', // Deep blue-gray for private areas
  secondary: '#FFFFFF', // Pure white for text and secondary actions
  secondardButtonColor: '#E5E5E5',
  white: '#FFFFFF', // Pure white for high contrast
  offBlack: '#181818', // Rich black for subtle shadows or deep backgrounds
  veryDark: '#0D0D0D', // Pitch black for extreme contrast
  mediumDark: '#f8f8f8', // Medium gray for muted elements
  medium: '#757575', // Neutral gray for placeholders
  light: '#2e2e2e', // Light gray for borders or subtle highlights
  ultraLight: '#1E1E1E', // Soft gray for hover states
  borderColor: '#4f4d4d', // Neutral dark border
  primaryGreen: '#00FF7A', // Vibrant green for success or highlights
  supportingBlue: '#4389f3', // Cool blue for links or supporting elements
  supportingBlueLight: '#1877f2', // Bright blue for prominent highlights
  supportingBlue50: '#6995d7', // Muted blue for backgrounds
  primaryRed: '#FF5252', // Bright red for errors
  success: '#4CAF50', // Soft green for success states
  pending: '#FFA500', // Bright orange for pending or warning states
  textColors: '#FFFFFF', // Pure white for text
  modalBackground: '#313131', // Black for modal background
};

export const springColors: ColorsMap = {
  bodyBackground: '#f8fef9', // Fresh and light green tint
  cardBackground: '#fcf8f3', // Soft peachy-white
  primary: '#FF69B4', // Pink flowers
  linerBackground: '#d8f3dc', // Soft mint green
  statusBackground: '#85c1e9', // Sky blue
  statusBackgroundCircel: '#aed9e0', // Light teal
  signBackground: '#fefae0', // Light yellow cream
  listColor: '#FF7F50', // Coral
  listItemColor: '#d3f4dc', // Soft green
  arrowColor: '#3d5a6c', // Deep teal
  progressTailColor: '#c8e6c9', // Light green
  primaryLight: '#ffe4e1', // Soft pink
  privacyColor: '#1b4d3e', // Forest green
  secondary: '#0c3c52', // Deep ocean blue
  secondardButtonColor: '#E5E5E5',
  white: '#FFFFFF',
  offBlack: '#1e2a30', // Dark teal
  veryDark: '#273d3f', // Dark forest green
  mediumDark: '#4a707a', // Soft greenish-gray
  medium: '#90c8b6', // Mint green
  light: '#d4f6c8', // Very light green
  ultraLight: '#f4fff1', // Greenish-white
  borderColor: '#d9e4dd', // Soft mint
  primaryGreen: '#00FF7A', // Vibrant green
  supportingBlue: '#6fa3ef', // Spring sky
  supportingBlueLight: '#44678a', // Pastel blue
  supportingBlue50: '#9ed8f3', // Light aqua
  primaryRed: '#ff6f61', // Red-orange tulips
  success: '#6bc856', // Fresh green
  pending: '#ffcc66', // Pastel orange
  textColors: '#4A4A4A',
  modalBackground: '#f0f0f0', // Light gray
};

export const summerColors: ColorsMap = {
  bodyBackground: '#fffdf2', // Bright, warm white
  cardBackground: '#fef9e7', // Sunny yellow
  primary: '#FF4500', // Sunset orange
  linerBackground: '#ffe6a7', // Light gold
  statusBackground: '#42a5f5', // Bright summer sky blue
  statusBackgroundCircel: '#81d4fa', // Light aqua
  signBackground: '#ffecb3', // Soft yellow
  listColor: '#ff7043', // Warm coral orange
  listItemColor: '#f6e1bf', // Cream
  arrowColor: '#444444', // Deep neutral
  progressTailColor: '#ffcc80', // Light orange
  primaryLight: '#ffab91', // Soft coral
  privacyColor: '#3c3b6e', // Twilight blue
  secondary: '#000000', // Neutral
  secondardButtonColor: '#E5E5E5',
  white: '#FFFFFF',
  offBlack: '#242424', // Warm charcoal
  veryDark: '#3d3d3d', // Neutral gray
  mediumDark: '#757575', // Warm gray
  medium: '#f2aead', // Pastel red
  light: '#f3dbba', // Light peach
  ultraLight: '#fff9e6', // Light yellow
  borderColor: '#ffeed2', // Light gold
  primaryGreen: '#76ff03', // Vibrant summer green
  supportingBlue: '#1e88e5', // Summer sky
  supportingBlueLight: '#4d8fac', // Aqua
  supportingBlue50: '#bbdefb', // Light sky blue
  primaryRed: '#f44336', // Bright red
  success: '#81c784', // Mint green
  pending: '#ffd600', // Bright yellow
  textColors: '#4A4A4A',
  modalBackground: '#f0f0f0', // Light gray
};

export const autumnColors: ColorsMap = {
  bodyBackground: '#fdf8f0', // Creamy beige
  cardBackground: '#fcf4e6', // Light tan
  primary: '#FF8C00', // Pumpkin orange
  linerBackground: '#fae5c3', // Light brown
  statusBackground: '#d35400', // Burnt orange
  statusBackgroundCircel: '#e59866', // Tan orange
  signBackground: '#ffe6b3', // Light orange cream
  listColor: '#6b4226', // Wood brown
  listItemColor: '#f3dab5', // Beige
  arrowColor: '#603913', // Dark brown
  progressTailColor: '#d7ccc8', // Soft brown
  primaryLight: '#ffcc80', // Soft orange
  privacyColor: '#3e2723', // Deep brown
  secondary: '#2c1e1e', // Warm black
  secondardButtonColor: '#E5E5E5',
  white: '#FFFFFF',
  offBlack: '#312f2f', // Charcoal brown
  veryDark: '#3d2f2f', // Dark brown-gray
  mediumDark: '#785d57', // Neutral brown
  medium: '#bf8550', // Autumn leaves
  light: '#ffe7b5', // Light orange
  ultraLight: '#fff2db', // Creamy white
  borderColor: '#e8d7b1', // Light tan
  primaryGreen: '#66bb6a', // Muted green
  supportingBlue: '#8eacbb', // Cool blue-gray
  supportingBlueLight: '#43829f', // Pale blue
  supportingBlue50: '#cfd8dc', // Light steel blue
  primaryRed: '#e57373', // Soft red
  success: '#4caf50', // Forest green
  pending: '#ff9800', // Burnt orange
  textColors: '#4A4A4A',
  modalBackground: '#f0f0f0', // Light gray
};

export const winterColors: ColorsMap = {
  bodyBackground: '#f2f7ff', // Cool ice blue
  cardBackground: '#e3f2fd', // Light blue
  primary: '#2196f3', // Cool blue
  linerBackground: '#dbe9f6', // Soft ice blue
  statusBackground: '#1565c0', // Deep blue
  statusBackgroundCircel: '#90caf9', // Light sky blue
  signBackground: '#e0f7fa', // Cool aqua
  listColor: '#1e3a5f', // Deep navy
  listItemColor: '#bbdefb', // Light sky blue
  arrowColor: '#2a2e35', // Neutral dark gray
  progressTailColor: '#cfd8dc', // Soft gray
  primaryLight: '#90caf9', // Light blue
  privacyColor: '#263238', // Cool dark gray
  secondary: '#000000', // Neutral black
  secondardButtonColor: '#E5E5E5',
  white: '#FFFFFF',
  offBlack: '#1b1c1d', // Charcoal gray
  veryDark: '#2c3033', // Neutral dark
  mediumDark: '#607d8b', // Gray-blue
  medium: '#96cb59', // Frosted green
  light: '#a8e9ae', // Light frosted green
  ultraLight: '#f1f8e9', // Frosted white
  borderColor: '#e1e7e9', // Frosted silver
  primaryGreen: '#81c784', // Mint green
  supportingBlue: '#42a5f5', // Sky blue
  supportingBlueLight: '#3d91b3', // Pale aqua
  supportingBlue50: '#cfd8dc', // Light steel blue
  primaryRed: '#ef5350', // Frosty red
  success: '#66bb6a', // Frosted green
  pending: '#ffa726', // Frosty orange
  textColors: '#4A4A4A',
  modalBackground: '#f0f0f0', // Light gray
};
