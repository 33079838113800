import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import {
  CustomDatePicker,
  CustomTimePicker,
  BoldLabel,
  BoldLabelAsterisk,
} from './style';
import { Dayjs } from 'dayjs';
import { ErrorMessage } from '../../component/_common/style';
import { useIsMedia } from '../../storyBook';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedDate: Dayjs | null;
  selectedTime: Dayjs | null;
  onDateChange: (newValue: Dayjs | null) => void;
  onTimeChange: (newValue: Dayjs | null) => void;
  validationErrors?: Record<string, string>;
}

const DateAndTimePicker: React.FC<Props> = ({
  selectedDate,
  selectedTime,
  onDateChange,
  onTimeChange,
  validationErrors = {},
}) => {
  const { isMobile, isTablet } = useIsMedia();
  const combined = isMobile || isTablet;
  const safeSelectedDate = selectedDate || null;
  const safeSelectedTime = selectedTime || null;
  const { t } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'TimePicker']}>
        <DemoItem
          label={
            <BoldLabel>
              {t('Date')}
              <BoldLabelAsterisk>*</BoldLabelAsterisk>
            </BoldLabel>
          }
        >
          <CustomDatePicker
            value={safeSelectedDate as Dayjs}
            onChange={onDateChange}
          />
          {combined && validationErrors.dateTime && (
            <ErrorMessage>{validationErrors.dateTime}</ErrorMessage>
          )}
        </DemoItem>

        <DemoItem
          label={
            <BoldLabel>
              {t('Time')}
              <BoldLabelAsterisk>*</BoldLabelAsterisk>
            </BoldLabel>
          }
        >
          <CustomTimePicker
            value={safeSelectedTime as Dayjs}
            onChange={onTimeChange}
            ampm
            format="hh:mm A"
          />
          {!combined && validationErrors.dateTime && (
            <ErrorMessage>{validationErrors.dateTime}</ErrorMessage>
          )}
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DateAndTimePicker;
