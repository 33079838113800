import { OptionsProps } from '../../../storyBook/Select/types';
import { useTranslation } from 'react-i18next';

export const UseStagesText = () => {
  const { t } = useTranslation();

  return [
    {
      header: t("What's your name?"),
      subText: t('Enter your first and last name to create an account.'),
    },
    {
      header: t('Enter your phone number and email'),
      subText: t(
        'Enter the mobile number and email on which you want to be contacted.'
      ),
    },
    {
      header: t('Enter your password'),
      subText: t('Enter the password you want to use for your account.'),
    },
  ];
};

export const feedbackOptionsList = (): OptionsProps[] => {
  const { t } = useTranslation();

  return [
    { value: 'General', label: t('General') },
    { value: 'Feature', label: t('Feature Request') },
    { value: 'Bug', label: t('Bug Report') },
    { value: 'Others', label: t('Others') },
  ];
};
