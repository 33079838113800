import { Box, Text, Flex, Button, Switch } from '../../../storyBook';
import theme from '../../../theme';
import { LuChevronRight } from 'react-icons/lu';
import { Hr, StyledLink } from '../../_common/style';
import useAuth from '../../hooks/useAuth';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useVoiceControl } from '../../Context/VoiceControlContext';
import { useTranslation } from 'react-i18next';

const MenuItem = ({
  text,
  to,
  isLast,
  icon,
  isSwitch = false,
  isOn = false,
  onToggle = () => {},
}: {
  text: string;
  to?: string;
  isLast?: boolean;
  icon?: boolean;
  isSwitch?: boolean;
  isOn?: boolean;
  onToggle?: () => void;
}) => (
  <StyledLink to={to}>
    <Flex alignItems="center" justifyContent="space-between" mb={3}>
      <Text>{text}</Text>
      {isSwitch ? (
        <Switch isOn={isOn} onClick={onToggle} />
      ) : icon ? (
        <LuChevronRight size={20} color={theme.colors.medium} />
      ) : null}
    </Flex>
    <Box mb={3}>{!isLast && <Hr />}</Box>
  </StyledLink>
);

const Profile = () => {
  const { logout } = useAuth();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { isVoiceEnabled, toggleVoiceControl } = useVoiceControl();
  const { t } = useTranslation();

  const speak = (text: string) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = 'en-US';
    window.speechSynthesis.speak(utterance);
  };

  const fallbackUserDetails = JSON.parse(
    localStorage.getItem('userDetails') || '{}'
  );
  const firstName = fallbackUserDetails?.firstName;

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <>
      <Box mb={5}>
        <Text as="h1" color={theme.colors.offBlack} mb={5} mt={2}>
          {t('Hello')} {firstName}
        </Text>
        <MenuItem
          text={t('Personal Information')}
          icon
          to={`${path}/details`}
        />
        <MenuItem
          text={t('Privacy Policy')}
          icon
          to={`${path}/privacy-policy`}
        />
        <MenuItem text={t('Feedback')} icon to={`${path}/feedback`} />
        <MenuItem text={t('Documents')} icon to={`${path}/documents`} />
        <MenuItem text={t('Theme')} icon to={`${path}/theme`} />
        <MenuItem text={t('Language')} icon to={`${path}/language`} />
        <MenuItem
          text={`${t('Voice Control')} ${
            isVoiceEnabled ? `${t('Enabled')}` : `${t('Disabled')}`
          }`}
          isSwitch
          isOn={isVoiceEnabled}
          onToggle={() => toggleVoiceControl(speak)}
          isLast
        />
      </Box>

      <Box width="100px">
        <Button
          styleType="secondaryOutline"
          size="medium"
          type="button"
          onClick={handleLogout}
          bold
        >
          {t('Logout')}
        </Button>
      </Box>
    </>
  );
};

export default Profile;
