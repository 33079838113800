import { Box, Text, Back, LanguageSelector } from '../../../storyBook';
import theme from '../../../theme';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

const Language = () => {
  const { t } = useTranslation();
  const handleBack = () => {
    history.back();
  };
  const handleLanguageChange = async (langCode: string) => {
    try {
      await i18n.changeLanguage(langCode);
      localStorage.setItem('appLanguage', langCode);
    } catch (err) {
      console.error('Error changing language:', err);
    }
  };
  return (
    <Box height="500px">
      <Box mb={3}>
        <Back arrow onClick={handleBack} />
      </Box>
      <Text as="h2" pb={2} color={theme.colors.mediumDark}>
        {t('Language Settings')}
      </Text>
      <Text pb={3}>
        {t('Select your preferred language for the application interface.')}
      </Text>
      <Box>
        <LanguageSelector onLanguageChange={handleLanguageChange} />
      </Box>
    </Box>
  );
};

export default Language;
