import dayjs from 'dayjs';
import morningImage from '../../../media/images/morning.png';
import afternoonImage from '../../../media/images/afternoon.png';
import eveningImage from '../../../media/images/evening.png';
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';

import {
  firstNameValidation,
  lastNameValidation,
  emailValidation,
  phoneNumberValidation,
  passwordValidation,
  confirmPasswordValidation,
} from '../validation';
import { ApiError } from '../apiCaller';

export const isFormValidForStage = (
  form: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
  },
  stage: number
): boolean => {
  const { t } = useTranslation();

  switch (stage) {
    case 1:
      return (
        !!form.firstName &&
        !firstNameValidation(form.firstName, t) &&
        !!form.lastName &&
        !lastNameValidation(form.lastName, t)
      );
    case 2:
      return (
        !!form.email &&
        !emailValidation(form.email, false, t) &&
        !!form.phoneNumber &&
        !phoneNumberValidation(form.phoneNumber, false, t)
      );
    case 3:
      return (
        !!form.password &&
        !passwordValidation(form.password, t) &&
        !!form.confirmPassword &&
        !confirmPasswordValidation(form.password, form.confirmPassword, t)
      );
    default:
      return false;
  }
};

// This function is used inside your hook where the state is available.
export const handleError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any,
  setError: React.Dispatch<React.SetStateAction<string | null>>
) => {
  if (err instanceof ApiError) {
    setError(err.message);
  } else {
    setError('Unknown error occurred');
  }
};

// Utility function to format dates
export const formatDate = (date: Date | undefined) => {
  if (!date) return 'No Date';

  return new Date(date).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
};

export const getTimelineLabel = (dateCreated: string | undefined): string => {
  if (!dateCreated) {
    // Handle the undefined case
    return 'Unknown Date'; // or any other default label you prefer
  }
  const createdDate = new Date(dateCreated);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  // Get the time difference from today in days
  const timeDiff = today.getTime() - createdDate.getTime();
  const dayDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

  if (createdDate.toDateString() === today.toDateString()) {
    return 'Today';
  } else if (createdDate.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else if (dayDiff <= 7) {
    return 'Previous 7 Days';
  } else if (dayDiff <= 30) {
    return 'Previous 30 Days';
  } else {
    // Return the month name
    return createdDate.toLocaleString('default', { month: 'long' });
  }
};

// parseTime function is used in pages/TimelineForm to parse the time string
export const parseTime = (timeString: string) => {
  const [time, modifier] = timeString.split(' ');
  const [hoursString, minutesString] = time.split(':');

  let hours = parseInt(hoursString, 10);
  const minutes = parseInt(minutesString, 10);

  if (modifier === 'PM' && hours !== 12) {
    hours = (hours + 12) % 24; // Adjust for PM
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0; // Adjust for midnight
  }

  return dayjs().hour(hours).minute(minutes);
};

// Helper function to determine the current time of day
export const useTimeOfDay = (t: (key: string) => string) => {
  const hour = new Date().getHours();
  if (hour < 12) {
    return {
      greetingTime: t('Morning'),
      image: morningImage,
      color: 'black', // Morning color
    };
  } else if (hour < 18) {
    return {
      greetingTime: t('Afternoon'),
      image: afternoonImage,
      color: 'black', // Afternoon color
    };
  } else {
    return {
      greetingTime: t('Evening'),
      image: eveningImage,
      color: 'white', // Evening color
    };
  }
};

// Dashboard time left helper functions
export const parseReminderTime = (reminderTime: string) => {
  const timeRegex = /(\d+):(\d+)\s*(AM|PM)/i;
  const match = reminderTime.match(timeRegex);

  if (!match) {
    return null;
  }

  let [hour] = match.slice(1, 3).map(Number);
  const minute = Number(match[2]);
  const period = match[3];

  // Convert to 24-hour format
  if (period.toUpperCase() === 'PM' && hour < 12) hour += 12;
  if (period.toUpperCase() === 'AM' && hour === 12) hour = 0;

  return isNaN(hour) || isNaN(minute) ? null : { hour, minute };
};

// Helper function to format time left
export const formatTimeLeft = (hours: number, minutes: number) => {
  if (hours === 0 && minutes === 0) return '';
  if (hours === 0) return `${minutes}m`;
  return `${hours}:${minutes.toString().padStart(2, '0')}h`;
};

// Derive a 256-bit hex key from a user’s Master Password
export const deriveHexKey = (masterPassword: string) => {
  // Create a SHA-256 hash of the master password, then convert to hex
  const hash = CryptoJS.SHA256(masterPassword);
  return hash.toString(CryptoJS.enc.Hex);
};

//decrypt function is used in pages/ResetPassword to decrypt the password
export const decrypt = (encryptedPassword: string, secretKey: string) => {
  try {
    const parts = encryptedPassword.split(':');
    if (parts.length !== 2) {
      throw new Error('Invalid encrypted password format');
    }
    const iv = CryptoJS.enc.Hex.parse(parts[0]);
    const encryptedData = CryptoJS.enc.Hex.parse(parts[1]);
    const key = CryptoJS.enc.Hex.parse(secretKey);

    const decrypted = CryptoJS.AES.decrypt(
      CryptoJS.lib.CipherParams.create({ ciphertext: encryptedData }),
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    const plainText = decrypted.toString(CryptoJS.enc.Utf8);
    return plainText;
  } catch (error) {
    console.error('Decryption error:', error);
    return '';
  }
};

//encrypt function is used in pages/ResetPassword to encrypt the password
export const encrypt = (password: string, secretKey: string) => {
  try {
    const iv = CryptoJS.lib.WordArray.random(16);
    const key = CryptoJS.enc.Hex.parse(secretKey);

    const encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return `${iv.toString()}:${encrypted.ciphertext.toString()}`;
  } catch (error) {
    console.error('Encryption error:', error);
    return '';
  }
};

// Helper function to parse date and time into a single Date object
export function parseDateTime(dateStr: string, timeStr: string) {
  const date = new Date(dateStr);
  const timeParts = timeStr.split(':');
  date.setHours(parseInt(timeParts[0]), parseInt(timeParts[1] || '0'), 0, 0);
  return date;
}

// Helper function to format date in different styles
export const formatDateStyles = (dateStr: string) => {
  const date = new Date(dateStr);
  const dayMonthYear = date
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    .toLowerCase()
    .replace(/\s/g, ' ');

  const monthYear = date
    .toLocaleDateString('en-GB', {
      month: 'short',
      year: 'numeric',
    })
    .toLowerCase()
    .replace(/\s/g, ' ');

  const dayMonth = date
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
    })
    .toLowerCase()
    .replace(/\s/g, ' ');

  return [dayMonthYear, monthYear, dayMonth];
};
