import { Box, Text, Back, RadioButton } from '../../../../storyBook';
import { useThemeContext } from '../../../../theme/ThemeContext';
import theme from '../../../../theme';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Theme = () => {
  const { selectedTheme, setSelectedTheme } = useThemeContext();
  const { t } = useTranslation();

  useEffect(() => {
    // Retrieve the theme from localStorage when the component mounts
    const savedTheme = localStorage.getItem('selectedTheme');
    if (savedTheme) {
      setSelectedTheme(
        savedTheme as
          | 'light'
          | 'dark'
          | 'spring'
          | 'summer'
          | 'autumn'
          | 'winter'
      );
    }
  }, [setSelectedTheme]);

  const handleBack = () => {
    history.back();
  };

  const handleChange = (val: string) => {
    setSelectedTheme(
      val as 'light' | 'dark' | 'spring' | 'summer' | 'autumn' | 'winter'
    );
    // Save the selected theme to localStorage
    localStorage.setItem('selectedTheme', val);
  };

  const themeOptions = [
    { value: 'light', label: t('light') },
    { value: 'dark', label: t('dark') },
    { value: 'spring', label: t('spring') },
    { value: 'summer', label: t('summer') },
    { value: 'autumn', label: t('autumn') },
    { value: 'winter', label: t('winter') },
  ];

  return (
    <Box p={2}>
      <Box mb={3}>
        <Back arrow onClick={handleBack} />
      </Box>
      <Text as="h2" pb={2} color={theme.colors.mediumDark}>
        {t('Theme')}
      </Text>
      <Text pb={3}>{t('Change the theme of the application')}</Text>
      <Box>
        <RadioButton
          name="theme"
          value={selectedTheme}
          onChange={(value: string) => handleChange(value)}
          ariaLabel={'radio button'}
          options={themeOptions.map((option) => ({
            value: option.value,
            label: option.label,
          }))}
        />
      </Box>
    </Box>
  );
};

export default Theme;
