import React from 'react';
import { Box, Text, Flex } from '../../../storyBook';
import Back from '../../../storyBook/Back';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

interface HeaderSignupProps {
  onBackClick: () => void | string;
  showJoinText?: boolean; // Optional prop
}

const HeaderSignup: React.FC<HeaderSignupProps> = ({
  onBackClick,
  showJoinText = false,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      backgroundColor={theme.colors.supportingBlue}
      mb={[3, 0]}
      p={12}
    >
      <Back onClick={onBackClick} chevron color />
      <Text as="h6" bold color={theme.colors.white}>
        {showJoinText ? `${t('Join')} Livediary` : 'Livediary'}
      </Text>
      <Box /> {/* This empty box ensures that the text remains centered */}
    </Flex>
  );
};

export default HeaderSignup;
