import { ThemeProvider } from 'styled-components';
import {
  lightTheme,
  darkTheme,
  springTheme,
  summerTheme,
  autumnTheme,
  winterTheme,
} from './theme';
import GlobalStyle from './theme/global';
import { AutoRefreshingRouter as Router } from './component/AutoRefreshingRouter';
import Routes from './component/routes';
import Layout from './component/Layout';
import CombinedProvider from './component/Context';
import NotificationRing from './component/Notifications/NotificationRing';
import ServiceWorkerManager from './ServiceWorkerManagement';
import NotificationPermissionManager from './NotificationPermissionManager';
import { ThemeContextProvider, useThemeContext } from './theme/ThemeContext';
import i18n from './component/i18n';
import { I18nextProvider } from 'react-i18next';

const ThemedApp = () => {
  const { selectedTheme } = useThemeContext();

  // Pick the theme object based on selectedTheme
  const currentTheme =
    selectedTheme === 'dark'
      ? darkTheme
      : selectedTheme === 'spring'
      ? springTheme
      : selectedTheme === 'summer'
      ? summerTheme
      : selectedTheme === 'autumn'
      ? autumnTheme
      : selectedTheme === 'winter'
      ? winterTheme
      : lightTheme;

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      <NotificationRing />
      <ServiceWorkerManager />
      <NotificationPermissionManager />
      <Router>
        <Layout>
          <Routes />
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <CombinedProvider>
        <ThemeContextProvider>
          <ThemedApp />
        </ThemeContextProvider>
      </CombinedProvider>
    </I18nextProvider>
  );
};

export default App;
