import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './othersLang/en.json';
import fr from './othersLang/fr.json';
import bn from './othersLang/bn.json';
import ar from './othersLang/ar.json';
import es from './othersLang/es.json';
import hi from './othersLang/hi.json';
import pt from './othersLang/pt.json';
import ru from './othersLang/ru.json';
import zh from './othersLang/zh.json';
import ja from './othersLang/ja.json';
import ko from './othersLang/ko.json';
import de from './othersLang/de.json';
import it from './othersLang/it.json';
import tr from './othersLang/tr.json';
import ur from './othersLang/ur.json';

const savedLanguage = localStorage.getItem('appLanguage') || 'en';
// Translation files for each language
const resources = {
  en: { translation: en },
  fr: { translation: fr },
  bn: { translation: bn },
  ar: { translation: ar },
  es: { translation: es },
  hi: { translation: hi },
  pt: { translation: pt },
  ru: { translation: ru },
  zh: { translation: zh },
  ja: { translation: ja },
  ko: { translation: ko },
  de: { translation: de },
  it: { translation: it },
  tr: { translation: tr },
  ur: { translation: ur },
};

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
