import styled from 'styled-components';
import { MdOutlineLanguage } from 'react-icons/md';
interface LanguageItemProps {
  isSelected: boolean;
}

export const LanguageSelectorWrapper = styled.div`
  width: 100%;
  position: relative;

  ${({ theme }) => theme.media.desktop} {
    width: 300px;
  }
`;

export const Dropdown = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 2px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.modalBackground};
  color: ${({ theme }) => theme.colors.textColors};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:focus {
    border: ${({ theme }) => theme.colors.supportingBlueLight};
  }
`;

export const LanguageListWrapper = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  background: ${({ theme }) => theme.colors.modalBackground};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
`;

export const SearchInput = styled.input`
  width: calc(100% - 16px);
  padding: 8px;
  margin: 0px 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.modalBackground};
  color: ${({ theme }) => theme.colors.textColors};
  border-radius: 2px;
  font-size: 14px;

  &:focus {
    border-color: ${({ theme }) => theme.colors.supportingBlueLight};
    outline: none;
  }
`;

export const LanguageItem = styled.div<LanguageItemProps>`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textColors};
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.supportingBlueLight : 'transparent'};
  &:hover {
    background-color: ${({ theme }) => theme.colors.ultraLight};
  }
`;

export const FlagIcon = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 10px;
  border-radius: 2px;
`;

export const LangIcon = styled(MdOutlineLanguage)`
  margin-left: 10px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.supportingBlueLight};
  cursor: pointer;
`;
