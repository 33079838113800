export const languages = [
  { code: 'en', name: 'English', flag: 'us' },
  { code: 'bn', name: 'বাংলা (Bangla)', flag: 'bd' },
  { code: 'ar', name: 'العربية (Arabic)', flag: 'sa' },
  { code: 'es', name: 'Español (Spanish)', flag: 'es' },
  { code: 'fr', name: 'Français (French)', flag: 'fr' },
  { code: 'hi', name: 'हिन्दी (Hindi)', flag: 'in' },
  { code: 'pt', name: 'Português (Portuguese)', flag: 'pt' },
  { code: 'ru', name: 'Русский (Russian)', flag: 'ru' },
  { code: 'zh', name: '中文 (Chinese)', flag: 'cn' },
  { code: 'ja', name: '日本語 (Japanese)', flag: 'jp' },
  { code: 'ko', name: '한국어 (Korean)', flag: 'kr' },
  { code: 'de', name: 'Deutsch (German)', flag: 'de' },
  { code: 'it', name: 'Italiano (Italian)', flag: 'it' },
  { code: 'tr', name: 'Türkçe (Turkish)', flag: 'tr' },
  { code: 'ur', name: 'اردو (Urdu)', flag: 'pk' },
];
