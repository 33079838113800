import styled from 'styled-components';
import { Box, Flex, Text } from '../../../storyBook';

export const CardWrapper = styled(Flex)`
  background: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.colors.linerBackground},
    ${({ theme }) => theme.colors.medium}
  );
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px; // This will create a gap around the card
  position: relative;
`;

export const Category = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.offBlack};
  margin-bottom: 10px;
`;

export const StatusIndicator = styled(Box)`
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    ${({ theme }) => theme.colors.statusBackground},
    ${({ theme }) => theme.colors.statusBackgroundCircel}
  );
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: white;
  margin-bottom: 20px;
`;

export const InfoRow = styled(Flex)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
`;

export const Label = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.mediumDark};
`;

export const Value = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.offBlack};
`;

export const Countdown = styled(Text)`
  font-size: 18px;
  color: ${({ hasPassed, theme }) =>
    hasPassed ? theme.colors.primaryRed : theme.colors.supportingBlue};
  margin-top: 20px;
  font-weight: 500;
`;

export const RotableContainer = styled(Box)`
  position: absolute;
  top: ${({ theme }) => theme.spacing.sm};
  right: ${({ theme }) => theme.spacing.sm};
`;
