import React, { useState, useEffect, useMemo } from 'react';
import {
  LanguageSelectorWrapper,
  Dropdown,
  LanguageListWrapper,
  SearchInput,
  LanguageItem,
  LangIcon,
} from './style';
import Flag from 'react-world-flags';
import { languages } from './languages';
import Text from '../Text';
import { Flex } from '../Grid';
import useIsMedia from '../hooks/useIsMedia';
import { useTranslation } from 'react-i18next';

interface LanguageSelectorProps {
  onLanguageChange: (code: string) => void;
}

const LanguageSelector = ({ onLanguageChange }: LanguageSelectorProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    () => localStorage.getItem('appLanguage') || 'en'
  );
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const { isMobile } = useIsMedia();
  const { t } = useTranslation();

  // Filtered languages memoized for performance
  const filteredLanguages = useMemo(
    () =>
      languages.filter((lang) =>
        lang.name.toLowerCase().includes(search.toLowerCase())
      ),
    [search]
  );

  useEffect(() => {
    // Notify parent of the initial language on component mount
    onLanguageChange(selectedLanguage);
  }, []);

  //Close Dropdown When Clicking Outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        isOpen &&
        e.target instanceof Node &&
        !document.getElementById('language-selector')?.contains(e.target) &&
        !(e.target as HTMLElement).closest('.lang-icon') // Exclude LangIcon clicks
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  const handleLanguageSelect = (code: string) => {
    setSelectedLanguage(code);
    setIsOpen(false);
    onLanguageChange(code);
    localStorage.setItem('appLanguage', code);
  };

  return (
    <>
      <Flex justifyContent={isMobile ? 'center' : 'left'} alignItems="center">
        <Text bold mb={2}>
          {t('Language')}
        </Text>
        <LangIcon
          className="lang-icon"
          size={20}
          onClick={() => setIsOpen((prev) => !prev)}
        />
      </Flex>
      <LanguageSelectorWrapper id="language-selector">
        {/* Dropdown Button */}

        <Dropdown
          onClick={() => setIsOpen((prev) => !prev)}
          aria-expanded={isOpen}
          aria-label="Language"
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Flag
              code={
                languages
                  .find((lang) => lang.code === selectedLanguage)
                  ?.flag.toUpperCase() || 'default'
              }
              style={{
                width: '20px',
                height: '15px',
                marginRight: '10px',
                borderRadius: '2px',
              }}
            />
            {languages.find((lang) => lang.code === selectedLanguage)?.name ||
              'Select Language'}
          </div>
        </Dropdown>

        {/* Language List */}
        {isOpen && (
          <LanguageListWrapper>
            <SearchInput
              type="text"
              placeholder={t('Search language...')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              aria-label="Search language"
            />
            {filteredLanguages.map((lang) => (
              <LanguageItem
                key={lang.code}
                onClick={() => handleLanguageSelect(lang.code)}
                isSelected={lang.code === selectedLanguage}
              >
                <Flag
                  code={lang.flag.toUpperCase()}
                  style={{
                    width: '20px',
                    height: '15px',
                    marginRight: '10px',
                    borderRadius: '2px',
                  }}
                />
                {lang.name}
              </LanguageItem>
            ))}
          </LanguageListWrapper>
        )}
      </LanguageSelectorWrapper>
    </>
  );
};

export default LanguageSelector;
