import { SwithchProps } from './types';
import { Toggle, SwitchContainer, Label } from './style';
import { useTranslation } from 'react-i18next';

const Switch: React.FC<SwithchProps> = ({ isOn, onClick, label }) => {
  const { t } = useTranslation();
  return (
    <>
      {label && <Label>{label}</Label>}
      <SwitchContainer isOn={isOn} onClick={onClick}>
        <Toggle isOn={isOn}>{isOn ? t('ON') : t('OFF')}</Toggle>
      </SwitchContainer>
    </>
  );
};

export default Switch;
