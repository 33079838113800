import { useState } from 'react';
import {
  Modal,
  Box,
  Text,
  TextInput,
  Card,
  Button,
  Flex,
} from '../../../../storyBook';
import { Hr } from '../../../_common/style';
import usePassword from '../../../hooks/usePassword';
import { siteValidation } from '../../../Utils/validation';
import { ErrorMessage } from '../../../_common/style';
import { encrypt, deriveHexKey } from '../../../Utils/helper'; // updated import
import { ImSpinner8 } from 'react-icons/im';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';

type AddPasswordModalProps = {
  setAddModal: (addModal: boolean) => void;
  isEditMode?: boolean;
  initialData?: { site: string; userName: string; password: string };
  onEdit?: (data: {
    website: string;
    username: string;
    password: string;
  }) => void;
};

const AddPasswordModal: React.FC<AddPasswordModalProps> = ({
  setAddModal,
  isEditMode = false,
  initialData,
  onEdit,
}) => {
  const [site, setSite] = useState(initialData?.site || '');
  const [userName, setUserName] = useState(initialData?.userName || '');
  const [password, setPassword] = useState(initialData?.password || '');
  const { addPassword, loading } = usePassword();
  const [siteError, setSiteError] = useState(false);
  const { t } = useTranslation();

  const { masterPasswords } = useAuth();
  // Derive a hex key from the master password
  const derivedHexKey = deriveHexKey(masterPasswords);

  const handleClose = () => {
    setAddModal(false);
  };

  const disabled = !site || !userName || !password;

  const handleSubmit = async () => {
    // 1) Encrypt the plain-text password using the derived hex key
    const encryptedPassword = encrypt(password, derivedHexKey);

    if (isEditMode && onEdit) {
      // For Editing
      onEdit({
        website: site,
        username: userName,
        password: encryptedPassword,
      });
    } else {
      // For Creating a new password
      try {
        await addPassword({
          website: site,
          username: userName,
          password: encryptedPassword,
        });
        setAddModal(false);
      } catch (error) {
        console.error('Error adding password:', error);
      }
    }
  };

  return (
    <Box>
      <Modal isOpen={true} onClose={handleClose}>
        <Box m={3}>
          <Card>
            <Box>
              <Text as="h3" mb={2} pt={3} color="mediumDark">
                {isEditMode ? t('Edit Password') : t('Add New Password')}
              </Text>
              <Hr mb={3} />
              <Box mb={4}>
                <Text as="span" mb={2}>
                  {t(
                    'Make sure that you are saving your current password for this site.'
                  )}
                </Text>
              </Box>
            </Box>

            <Box mb={4}>
              <TextInput
                autoFocus
                placeholder={t('Site (example.com)')}
                value={site}
                onChange={(e) => setSite(e.target.value)}
                validation={siteValidation}
                onValidation={(isValid) => setSiteError(isValid)}
                disabled={isEditMode}
              />
              {siteError && <ErrorMessage>{siteError}</ErrorMessage>}
              <TextInput
                placeholder={t('Username')}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <TextInput
                type="password"
                placeholder={t('Password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>

            <Hr mb={4} />
            <Flex flexDirection="row" textAlign="end" pb={3}>
              <Box mr={3}>
                <Button
                  styleType="primary"
                  size="small"
                  type="submit"
                  bold
                  onClick={handleSubmit}
                  disabled={disabled}
                >
                  {loading ? (
                    <ImSpinner8 size={20} style={{ verticalAlign: 'middle' }} />
                  ) : isEditMode ? (
                    t('Update')
                  ) : (
                    t('Save')
                  )}
                </Button>
              </Box>
              <Box>
                <Button
                  styleType="secondary"
                  size="small"
                  type="button"
                  onClick={handleClose}
                >
                  {t('Cancel')}
                </Button>
              </Box>
            </Flex>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddPasswordModal;
